// src/pages/UserMakes/ItemMenuMakes.tsx

import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { AppContext } from "@contexts/AppContext"; // KEEP FOR NOW
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@components/DropdownMenu/DropdownMenu";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import {
  FileCopy as CopyIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  AddToDrive as GoogleDriveIcon,
  PictureAsPdf as PDFIcon,
} from "@mui/icons-material";

interface ItemMenuProps {
  trigger: React.ReactNode;
  sideOffset?: number;
  align?: "start" | "center" | "end";
  onMakeCoverLetter: () => void;
  onDelete: () => void;
  onExpand: () => void;
  googleDriveUrl?: string;
  pdfUrl?: string;
}

const ItemMenuMakes: React.FC<ItemMenuProps> = ({
  trigger,
  sideOffset = 5,
  align = "end",
  onMakeCoverLetter,
  onDelete,
  onExpand,
  googleDriveUrl,
  pdfUrl,
}) => {
  /* KEEP FOR NOW */
  /* const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("AppContext must be used within an AppProvider.");
  }
  const { userStore } = useContext(AppContext); */

  const handleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onExpand();
    // console.log("Expanding row");
  };

  const handleGoogleDrive = () => {
    if (googleDriveUrl) {
      window.open(googleDriveUrl, "_blank", "noopener,noreferrer");
    } else {
      toast.error("Google Drive URL is not available.");
    }
  };

  const handlePDF = () => {
    if (pdfUrl) {
      window.open(pdfUrl, "_blank", "noopener,noreferrer");
    } else {
      toast.error("PDF URL is not available.");
    }
  };

  const handleMakeCoverLetter = async () => {
    try {
      await onMakeCoverLetter();
      toast.success("Make cover letter initiated.");
    } catch (error) {
      toast.error("Failed to initiate cover letter.");
    }
  };

  const handleDelete = async () => {
    try {
      await onDelete();
      toast.success("Make deleted.");
    } catch (error) {
      toast.error("Failed to delete make.");
    }
  };

  // Menu items with folding mechanic
  const menuItems: DropdownMenuItem[] = [
    {
      label: "Expand",
      icon: <ExpandMoreIcon fontSize="small" />,
      // @ts-ignore
      // Seems to be working, but there's an error with the stop propagation
      onSelect: handleExpand,
    },
    {
      label: "Google Drive",
      icon: <GoogleDriveIcon fontSize="small" />,
      onSelect: handleGoogleDrive,
    },
    {
      label: "PDF",
      icon: <PDFIcon fontSize="small" />,
      onSelect: handlePDF,
    },
    {
      label: "Make Cover Letter",
      icon: <CopyIcon fontSize="small" />,
      onSelect: handleMakeCoverLetter,
    },
    {
      label: "Delete",
      icon: <DeleteIcon fontSize="small" />,
      onSelect: handleDelete,
    },
    // Grouped items
    // Keep this commented-out code for now
    /* {
      id: "export", // Unique ID for the group
      groupLabel: "Export...",
      items: [
        { label: "RTF", onSelect: () => console.log("Export as RTF") },
        { label: "PDF", onSelect: () => console.log("Export as PDF") },
        { label: "HTML", onSelect: () => console.log("Export as HTML") },
        { label: "DOCX", onSelect: () => console.log("Export as DOCX") },
        { label: "JPG", onSelect: () => console.log("Export as JPG") },
        { label: "ePub", onSelect: () => console.log("Export as ePub") },
      ],
    }, */
  ];

  return (
    <>
      <DropdownMenu
        trigger={trigger}
        items={menuItems}
        sideOffset={sideOffset}
        align={align}
      />
    </>
  );
};

export default ItemMenuMakes;
