// src/pages/BaseResumes/BaseResumes.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";

// Styled Components

export const BaseResumesContainer = styled(Primitive.ModuleContainer)`
  display: flex;
  width: 100%;
  align-items: center;
  overflow-x: scroll;

  @media (max-width: 640px) {
    padding: 0 0 80px 0;
    width: 100vw;
  }
`;

export const BaseResumesHeader = styled(Primitive.ModuleHeader)`
  /* Styles */
`;

export const BaseResumesTableContainer = styled.div`
  overflow-x: scroll;
`;

export const BaseResumesStyledTable = styled(Primitive.Table)`
  min-width: 40%;
  width: max-content;
  align-items: center;

  @media (max-width: 640px) {
    padding: 0;
    width: 100vw;
  }
`;

export const BaseResumesTableHeader = styled(Primitive.TableHeader)`
  /* Styles */
`;

export const BaseResumesTableRow = styled(Primitive.TableRow)`
  /* Styles */
`;

export const BaseResumesTableData = styled(Primitive.TableData)`
  /* Styles */
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const SetDefaultButton = styled(Primitive.TableButton)`
  /* Styles */
`;

export const DeleteButton = styled(Primitive.TableButton)`
  background: #ff4d4f;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const SetupResumeButtonContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SetupResumeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  vertical-align: middle;
  margin-top: 20px;
  padding: 0px 2px;
  background: ${({ theme }) => theme.colors.buttonPrimary};
  color: #fff;
  border: solid 1px #ffffff;
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  max-width: 100%;
  transition: filter 0.3s ease;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.8);
    }
  }

  svg {
    width: 32px;
    height: 32px;
    transform: translateY(2px);
  }
`;

export const SetupResumeButtonSpan = styled.span`
  vertical-align: middle;
  margin: 6px;
`;

export const BaseResumesStyledIcon = styled(Primitive.TableIconButton)`
  /* styles */
`;

export const BaseResumesDefaultCheck = styled.span`
  font-size: 18px;
`;

export const BaseResumesLoadingMessage = styled(
  Primitive.LoadingMessageContainer
)``;

export const BaseResumesSVGWrapper = styled(Primitive.TableSVGWrapper)<{
  size?: number;
}>`
  /* Additional Styles */
`;
