// src/components/TopBar/components/TopBarMenuButton.tsx

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  MenuRounded as MenuIcon,
  // Close as CloseIcon,
} from "@mui/icons-material";

// Styled Components
const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  padding: 0;
  // margin-right: -10px;

  @media (hover: hover) {
    &:hover {
      background-color: #eee;
      border-radius: 4px;
    }
  }

  /* position: fixed;
  top: 10px;
  left: 10px;
  z-index: 100; */
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  svg {
    font-size: 1.85rem;
    // fill: #235;
    fill: ${({ theme }) => theme.colors.bgSecondary};
  }
`;

// Component Props
interface TopBarMenuButtonProps {
  onClick?: () => void;
  isToggled?: boolean; // New prop to indicate toggle state
}

export const TopBarMenuButton: React.FC<TopBarMenuButtonProps> = ({
  onClick,
  isToggled = false,
}) => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const target = document.getElementById("topBarMenuButton");
    if (target) {
      setPortalTarget(target);
    } else {
      console.error("Portal target #topBarMenuButton not found");
    }
  }, []);

  if (!portalTarget) {
    // Optionally, you can return a fallback UI or null
    return null;
  }

  return ReactDOM.createPortal(
    <MenuButtonContainer>
      <MenuButton aria-label="menu" onClick={onClick}>
        <MenuIcon />
      </MenuButton>
    </MenuButtonContainer>,
    portalTarget
  );
};
