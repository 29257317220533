// src/App.tsx

import React, { useEffect, useContext } from "react";
import { AppContext } from "@contexts/AppContext";
import { observer } from "mobx-react-lite";

import { initializeAuth, handleRedirectResult } from "@services/auth/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "@config/firebaseConfig";

import { ToastContainer } from "react-toastify";
import { TopBar } from "@modules/TopBar/TopBar";
import { LoadingScreen } from "@components/LoadingScreen/LoadingScreen";
import { AppRouter } from "@routes/AppRouter";

const auth = getAuth(app);

const App: React.FC = observer(() => {
  const { userStore } = useContext(AppContext);

  useEffect(() => {
    const initialize = async () => {
      await initializeAuth();
      await handleRedirectResult();

      // ... The existing code you have that checks the URL
      // for testEmail, and sets up onAuthStateChanged, etc.
      // For example:
      const unsubscribe = onAuthStateChanged(auth, (authUser) => {
        userStore.setUser(authUser);
        userStore.setLoading(false);
      });

      return () => unsubscribe();
    };

    initialize();
  }, [userStore]);

  if (userStore.loading) {
    return <LoadingScreen />;
  }

  // Once loading is done, we render the top bar, and the router
  return (
    <>
      <TopBar />
      <AppRouter />
      <ToastContainer
        position="top-right"
        autoClose={750}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        progressClassName="toast-progress"
      />
    </>
  );
});

export default App;
