// src/components/SideBar/SideBar.tsx

import React from "react";
import { NavLink } from "react-router-dom";

import {
  SidebarContainer,
  MenuItem,
  IconWrapper,
  SeparatorContainer,
  Separator,
} from "./SideBar.styled";
import { GoogleDriveSVG } from "@assets/GoogleDriveSVG";
import {
  AutoAwesomeTwoTone as AutoAwesomeIcon,
  SpaceDashboardTwoTone as SpaceDashboardIcon,
  AssignmentTwoTone as AssignmentIcon,
  Add as AddIcon,
  InstallDesktop as InstallIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

interface SideBarProps {
  sidebarWidth: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isMobile: boolean;
  onClose: () => void;
}

export const SideBar: React.FC<SideBarProps> = ({
  sidebarWidth,
  onMouseEnter,
  onMouseLeave,
  isMobile,
  onClose,
}) => {
  // External link handlers
  const handleOpenGoogleDrive = () => {
    window.open("https://drive.google.com", "_blank", "noopener,noreferrer");
    if (isMobile) onClose();
  };

  const handleOpenAddCredits = () => {
    window.open("https://demolp.s-kode.com", "_blank", "noopener,noreferrer");
    if (isMobile) onClose();
  };

  const handleOpenInstallExtension = () => {
    window.open(
      "https://chromewebstore.google.com/detail/autocv/micjohkmnpfkcnfoifijfdffpcajjiod",
      "_blank",
      "noopener,noreferrer"
    );
    if (isMobile) onClose();
  };

  return (
    <SidebarContainer
      $sidebarWidth={sidebarWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $isMobile={isMobile}
    >
      <div style={{ height: "10px" }} />

      {/* Make => /make */}
      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <NavLink
          to="/make"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
          onClick={() => {
            if (isMobile) onClose();
          }}
        >
          <IconWrapper>
            <AutoAwesomeIcon />
          </IconWrapper>
          <span>Make</span>
        </NavLink>
      </MenuItem>

      {/* Setup Resume => /setup-resume */}
      {/* <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <NavLink
          to="/setup-resume"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
          onClick={() => {
            if (isMobile) onClose();
          }}
        >
          <IconWrapper>
            <AutoAwesomeIcon />
          </IconWrapper>
          <span>Setup Resume</span>
        </NavLink>
      </MenuItem> */}

      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>

      {/* Dashboard => / */}
      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <NavLink
          to="/"
          end // for exact match
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
          onClick={() => {
            if (isMobile) onClose();
          }}
        >
          <IconWrapper>
            <SpaceDashboardIcon />
          </IconWrapper>
          <span>Dashboard</span>
        </NavLink>
      </MenuItem>

      {/* Base Resumes => /base-resumes */}
      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <NavLink
          to="/base-resumes"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
          }}
          onClick={() => {
            if (isMobile) onClose();
          }}
        >
          <IconWrapper>
            <AssignmentIcon />
          </IconWrapper>
          <span>Base Resumes</span>
        </NavLink>
      </MenuItem>

      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>

      {/* External links: Google Drive, Install Extension, Add Credits */}
      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <div
          style={{ display: "flex", alignItems: "center", width: "100%" }}
          onClick={handleOpenGoogleDrive}
        >
          <IconWrapper>
            <div
              style={{
                display: "flex",
                scale: "0.25",
                maxHeight: "24px",
                transform: "translateY(-24px)",
              }}
            >
              <GoogleDriveSVG />
            </div>
          </IconWrapper>
          <span>Google Drive</span>
          <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
        </div>
      </MenuItem>

      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <div
          style={{ display: "flex", alignItems: "center", width: "100%" }}
          onClick={handleOpenInstallExtension}
        >
          <IconWrapper>
            <InstallIcon />
          </IconWrapper>
          <span>Install Extension</span>
          <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
        </div>
      </MenuItem>

      <MenuItem $active={false} $sidebarWidth={sidebarWidth}>
        <div
          style={{ display: "flex", alignItems: "center", width: "100%" }}
          onClick={handleOpenAddCredits}
        >
          <IconWrapper>
            <AddIcon />
          </IconWrapper>
          <span>Add Credits</span>
          <OpenInNewIcon style={{ marginLeft: "auto", fontSize: "16px" }} />
        </div>
      </MenuItem>
    </SidebarContainer>
  );
};
