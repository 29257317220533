// src/components/LoadingScreen/LoadingScreen.tsx
// https://www.davidhu.io/react-spinners/

import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import colors from "../../theme/colors"; // Import your colors

const LoadingScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.bgSecondary};
  z-index: 9000;
`;

export const LoadingScreen: React.FC = () => {
  return (
    <LoadingScreenContainer>
      <ClipLoader color={colors.white} size={150} />
    </LoadingScreenContainer>
  );
};

const LoadingComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 9000;
`;

export const LoadingComponent: React.FC = () => {
  return (
    <LoadingComponentContainer>
      <ClipLoader color={colors.grayLight} size={100} />
    </LoadingComponentContainer>
  );
};
