// src/pages/BaseResumes/hooks/useBaseResumes.ts

import { useState, useEffect, useCallback, useContext } from "react";
import { AppContext } from "@contexts/AppContext";
import { fetchUserBaseResumes } from "../services/baseResumesService";
import { useWebhooks } from "./useWebhooks";
import { UserBaseResume } from "../types";

export const useBaseResumes = () => {
  const { userStore } = useContext(AppContext);
  const [resumes, setResumes] = useState<UserBaseResume[]>([]);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});
  const [isSettingDefault, setIsSettingDefault] = useState<{
    [key: string]: boolean;
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { sendDeleteWebhook, sendUpdateDefaultResumeWebhook } = useWebhooks();

  const userEmail = userStore.email || userStore.testEmail;

  useEffect(() => {
    const fetchResumes = async (userEmail: string) => {
      setLoading(true);
      const userBaseResumes = await fetchUserBaseResumes(userEmail);
      setResumes(userBaseResumes);
      setLoading(false);
    };

    if (userEmail) {
      fetchResumes(userEmail);
    } else {
      console.error("No user email found. Cannot fetch resumes.");
      setLoading(false);
    }
  }, [userEmail]);

  const handleDeleteResume = useCallback(
    async (docId: string) => {
      if (!userEmail) {
        console.error("No user email found for deleting the resume.");
        return;
      }

      setIsDeleting((prev) => ({ ...prev, [docId]: true }));

      const success = await sendDeleteWebhook(docId, userEmail);

      if (success) {
        setResumes((prevResumes) =>
          prevResumes.filter((resume) => resume.id !== docId)
        );
        console.log("Successfully deleted document with ID:", docId);
      } else {
        console.error("Failed to delete document with ID:", docId);
      }

      setIsDeleting((prev) => ({ ...prev, [docId]: false }));
    },
    [userEmail, sendDeleteWebhook]
  );

  const handleSetDefaultResume = useCallback(
    async (docId: string) => {
      if (!userEmail) {
        console.error("User email is not available.");
        return;
      }

      setIsSettingDefault((prev) => ({ ...prev, [docId]: true }));

      const success = await sendUpdateDefaultResumeWebhook(docId, userEmail);

      if (success) {
        console.log(`Set Default Resume for docId ${docId} successfully.`);
        setResumes((prevResumes) =>
          prevResumes.map((resume) => ({
            ...resume,
            isDefault: resume.id === docId,
          }))
        );
      } else {
        console.error(`Failed to set default resume for docId ${docId}.`);
      }

      setIsSettingDefault((prev) => ({ ...prev, [docId]: false }));
    },
    [userEmail, sendUpdateDefaultResumeWebhook]
  );

  return {
    resumes,
    loading,
    isDeleting,
    isSettingDefault,
    handleDeleteResume,
    handleSetDefaultResume,
  };
};
