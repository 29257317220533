// src/pages/UserMakes/components/AllUserMakesButton.styled.ts

import styled from "styled-components";
import * as Primitive from "@components/primitives";

export const AllUserMakesButtonContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const AllUserMakesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 20px;
  background: #eee;
  color: #000000;
  border: solid 1px #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  max-width: 100%;
  transition: filter 0.3s ease;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
  }
`;

/* export const UserMakesButtonIcon = styled.img`
  width: auto;
  height: 24px;
  vertical-align: middle;
  padding-right: 8px;
`; */

export const UserMakesSVGWrapper = styled(Primitive.TableSVGWrapper)<{
  size?: number;
}>`
  text-decoration: none;
`;
