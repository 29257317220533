// src/layouts/Dashboard/Dashboard.tsx

import React from "react";
import { Outlet } from "react-router-dom";
import { DashboardContainer, ContentContainer } from "./Dashboard.styled";
import { SideBar } from "@modules/SideBar/SideBar";
import { TopBarMenuButton } from "@modules/TopBar/components/TopBarMenuButton";
import { useSidebarLogic } from "./hooks/useSideBarLogic";

interface DashboardProps {
  user: any | null;
  testEmail: string | null;
}

export const Dashboard: React.FC<DashboardProps> = ({ user, testEmail }) => {
  const {
    isMenuToggled,
    sidebarWidth,
    isMobile,
    handleTopBarMenuClick,
    handleMouseEnter,
    handleMouseLeave,
    handleCloseSidebar,
  } = useSidebarLogic();

  return (
    <>
      <TopBarMenuButton
        onClick={handleTopBarMenuClick}
        isToggled={isMenuToggled}
      />

      <DashboardContainer>
        {/* Only render the sidebar if not mobile, OR if toggled on mobile */}
        {(!isMobile || isMenuToggled) && (
          <SideBar
            sidebarWidth={sidebarWidth}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isMobile={isMobile}
            onClose={handleCloseSidebar}
          />
        )}

        <ContentContainer $isMobile={isMobile}>
          {/* Nested route content goes here via <Outlet> */}
          <Outlet />
        </ContentContainer>
      </DashboardContainer>
    </>
  );
};
