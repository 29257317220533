// src/pages/SignIn/SignIn.tsx

import React from "react";
import { signInWithGoogle } from "@services/auth/auth";
import {
  SignInContainer,
  SignInLogoContainer,
  SignInPageContainer,
  SignInSubtitle,
  SignInTitle,
  LogoImage,
} from "./SignIn.styled";
import logoSVG from "@assets/logo6.svg";
import { GoogleSignInButton } from "./components/GoogleSignInButton";

export const SignIn: React.FC = () => {
  const handleSignIn = () => {
    signInWithGoogle();
  };

  return (
    <SignInPageContainer>
      <SignInContainer>
        <SignInLogoContainer>
          <LogoImage src={logoSVG} alt="RunoIcon" />
        </SignInLogoContainer>
        <SignInTitle>Sign in to RunoJobs</SignInTitle>

        {/* Google Sign-In Button */}
        <GoogleSignInButton
          onClick={handleSignIn}
          ariaLabel="Sign in with Google"
        />

        <SignInSubtitle>For new and returning users.</SignInSubtitle>
      </SignInContainer>
    </SignInPageContainer>
  );
};
