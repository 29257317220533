// src/components/SubMenu.tsx

import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ChevronRight } from "@mui/icons-material";
import {
  StyledArrowIcon,
  DropdownItem,
  DropdownSeparator,
  DropdownSubmenuItem,
  SubMenuContent,
} from "./DropdownMenu.styled";
import { DropdownMenuItem } from "./DropdownMenu";
import { useMediaQuery } from "react-responsive";

interface SubmenuProps {
  item: DropdownMenuItem;
}

export const Submenu: React.FC<SubmenuProps> = ({ item }) => {
  // Define the breakpoint for mobile devices
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  // Detect if the device is a touch device
  const isMobile = useMediaQuery({
    query: "(pointer: coarse) and (hover: none)",
  });

  return (
    <DropdownMenuPrimitive.Sub>
      <DropdownMenuPrimitive.SubTrigger asChild>
        <DropdownSubmenuItem $isMobile={isMobile}>
          {item.icon && item.icon}
          {item.groupLabel}
          <StyledArrowIcon>
            <ChevronRight />
          </StyledArrowIcon>
        </DropdownSubmenuItem>
      </DropdownMenuPrimitive.SubTrigger>
      <DropdownMenuPrimitive.Portal>
        <SubMenuContent
          side={isMobile ? "bottom" : "right"} // Appear below on mobile
          sideOffset={isMobile ? 2 : 2} // Adjust offset if needed
          align={isMobile ? "start" : "start"} // Alignment remains the same
          alignOffset={isMobile ? 0 : 0}
          avoidCollisions={true} // Enable collision avoidance
          $isMobile={isMobile}
        >
          {item.items?.map((subItem, subIndex) =>
            subItem.separator ? (
              <DropdownSeparator
                key={`sub-separator-${subIndex}`}
                $isMobile={isMobile}
              />
            ) : (
              <DropdownItem
                key={`${item.id}-${subIndex}`}
                onSelect={(event: any) => {
                  if (subItem.keepOpen) {
                    event.preventDefault();
                  }
                  subItem.onSelect?.();
                }}
                $isMobile={isMobile}
              >
                {subItem.icon && subItem.icon}
                {subItem.label}
              </DropdownItem>
            )
          )}
        </SubMenuContent>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Sub>
  );
};
