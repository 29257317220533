// src/components/TopBar/TopBar.tsx

import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { AppContext } from "../../contexts/AppContext";
import {
  TopBarContainer,
  TopBarLeft,
  TopBarCenter,
  TopBarRight,
} from "./TopBar.styled";
import { AccountButton } from "./components/AccountButton/AccountButton";
import { TopBarAppLogo } from "./components/TopBarAppLogo";

export const TopBar: React.FC = observer(() => {
  const { userStore } = useContext(AppContext);

  const handleLogoClick = () => {
    window.location.href = "/";
  };

  return (
    <TopBarContainer>
      {/* Left-aligned App Logo and Name */}
      <TopBarLeft>
        <div id="topBarMenuButton"></div>
        {/* <TopBarMenuButton onClick={handleMenuClick} /> */}
        <TopBarAppLogo />
      </TopBarLeft>

      {/* Center Content (Portal Div) */}
      <TopBarCenter id="top-bar-center"></TopBarCenter>

      <TopBarRight>
        {/* Right-aligned Account Button */}
        {userStore.isAuthenticated && <AccountButton />}
      </TopBarRight>
    </TopBarContainer>
  );
});

export default TopBar;
