// src/layouts/Dashboard/Dashboard.styled.ts

import styled from "styled-components";

export const DashboardContainer = styled.div`
  margin-top: 56px;
  padding-top: 0;
  /* display: flex; */
`;

/* export const ContentContainer = styled.div<{
  $sidebarWidth: number;
  $isMobile: boolean;
}>`
  margin-left: ${({ $isMobile, $sidebarWidth }) =>
    $isMobile ? "0" : `${$sidebarWidth}px`};
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
  transition: margin-left 0.3s ease; 
  width: ${({ $isMobile, $sidebarWidth }) =>
    $isMobile ? "100vw" : `calc(100% - ${$sidebarWidth}px)`};

  @media (max-width: 600px) {
    padding: 0;
  }
`; */

export const ContentContainer = styled.div<{
  $isMobile: boolean;
}>`
  margin-left: 28px;
  margin-top: 0;
  padding: 20px;
  padding-top: 0;
  width: 100%;

  @media (max-width: 640px) {
    padding: 0;
    margin-left: 0;
  }

  /* @media (max-width: 768px) {
    margin-left: 0;
  } */
`;
