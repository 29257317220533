// src/pages/Try/components/TryInputResume/TryInputResume.tsx

import React, { useState, useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
// import { AppContext } from "../../contexts/TryContext";
import { AppContext } from "@contexts/AppContext";
import {
  TrySubmoduleContainer,
  TrySubmoduleHeader,
  TryButton,
  TryButtonLarge,
  TryTextArea,
  TryInput,
} from "pages/Try/TryModule.styled";
import { TryInputAreaContainer } from "./TryInputResume.styled";
import {
  CloudUpload as UploadIcon,
  FontDownload as InputIcon,
  AddToDrive as GoogleDriveIcon,
  FileUploadRounded as FileUploadIcon,
} from "@mui/icons-material";
import { GooglePicker } from "../";
import { GoogleDriveSVG } from "@assets/GoogleDriveSVG";
import { config } from "config";
import { GoogleDocThumbnail } from "@components/GoogleDocThumbnail/GoogleDocThumbnail";
import { SVGWrapper } from "@components/primitives";

interface TryInputResumeProps {
  onNext: () => void;
}

export const TryInputResume: React.FC<TryInputResumeProps> = observer(
  ({ onNext }) => {
    const [inputMethod, setInputMethod] = useState<
      "paste" | "upload" | undefined
    >(undefined);
    // Replace useState with useRef for accessToken
    const accessTokenRef = useRef<string | null>(null);
    const { tryStore } = useContext(AppContext); // Destructure tryStore

    // Persist state whenever inputs change
    useEffect(() => {
      tryStore.persistState();
    }, [tryStore.selectedDoc, tryStore.jobDescriptionInput]);

    // Restore selection if exists
    useEffect(() => {
      if (tryStore.selectedDoc) {
        console.log(
          "Restoring selected Document from store:",
          tryStore.selectedDoc
        );
        // Optionally, display the selected document's details in the UI
      }
    }, [tryStore.selectedDoc]);

    const extractTextFromDocument = (document: any): string => {
      let text = "";

      if (document.body && document.body.content) {
        const contentArray = document.body.content;
        contentArray.forEach((content: any) => {
          if (content.paragraph && content.paragraph.elements) {
            content.paragraph.elements.forEach((element: any) => {
              if (element.textRun && element.textRun.content) {
                text += element.textRun.content;
              }
            });
          }
        });
      }

      return text;
    };

    // Handlers to switch input methods
    const handleSelectPaste = () => setInputMethod("paste");
    const handleSelectUpload = () => setInputMethod("upload");
    const handleBack = () => setInputMethod(undefined);

    // Handler for input changes
    const handleResumeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      tryStore.setResumeInput(e.target.value);
    };

    const handlePickerChange = async (data: any) => {
      console.log("on change:", data);
      console.log(
        "Access token in handlePickerChange:",
        accessTokenRef.current
      );
      if (!accessTokenRef.current) {
        console.error("Access token is null or undefined.");
        return;
      }
      if (data.action === window.google.picker.Action.PICKED) {
        const doc = data.docs[0];
        /* try {
          // Fetch document metadata
          const metadataResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}?fields=name,webViewLink,mimeType,thumbnailLink,createdTime,modifiedTime`,
            {
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
              },
            }
          ); */

        try {
          // Fetch document metadata including the 'owners' field
          const metadataResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}?fields=name,webViewLink,mimeType,thumbnailLink,createdTime,modifiedTime,owners(displayName,emailAddress)`, // Added owners with emailAddress
            {
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
              },
            }
          );

          if (!metadataResponse.ok) {
            const errorText = await metadataResponse.text();
            throw new Error(
              `Error fetching file details: ${metadataResponse.status} ${metadataResponse.statusText} - ${errorText}`
            );
          }

          const fileDetails = await metadataResponse.json();

          const selectedDoc = {
            id: doc.id,
            title: fileDetails.name || doc.name || doc.title,
            url: fileDetails.webViewLink || doc.url,
            mimeType: fileDetails.mimeType || doc.mimeType,
            thumbnailUrl: fileDetails.thumbnailLink || doc.thumbnailUrl,
            createdDate: fileDetails.createdTime || doc.createdDate,
            modifiedDate: fileDetails.modifiedTime || doc.modifiedDate,
            ownerEmail:
              fileDetails.owners && fileDetails.owners.length > 0
                ? fileDetails.owners[0].emailAddress // Store owner's email
                : undefined,
          };

          console.log("Selected Document:", selectedDoc);
          tryStore.setSelectedDoc(selectedDoc); // Store in MobX

          // Create a copy of the file
          const copyResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${doc.id}/copy`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name: `Copy of ${selectedDoc.title}`,
              }),
            }
          );

          if (!copyResponse.ok) {
            const errorText = await copyResponse.text();
            throw new Error(
              `Error copying file: ${copyResponse.status} ${copyResponse.statusText} - ${errorText}`
            );
          }

          const copiedFile = await copyResponse.json();

          // Now export the content of the copied file
          const contentResponse = await fetch(
            `https://www.googleapis.com/drive/v3/files/${copiedFile.id}/export?mimeType=text/plain`,
            {
              headers: {
                Authorization: `Bearer ${accessTokenRef.current}`,
              },
            }
          );

          if (!contentResponse.ok) {
            const errorText = await contentResponse.text();
            throw new Error(
              `Error fetching document content: ${contentResponse.status} ${contentResponse.statusText} - ${errorText}`
            );
          }

          const textContent = await contentResponse.text();
          console.log("Document Text Content:", textContent);

          // Store the text content in the MobX store
          tryStore.setResumeInput(textContent);

          // Automatically advance to the next step
          onNext();
        } catch (error) {
          console.error("Error processing document:", error);
          // Handle error as needed
        }
      }
    };

    const clearSelectDoc = () => {
      tryStore.setSelectedDoc(null);
    };

    // Correctly define onAuthenticate
    const onAuthenticate = (token: string) => {
      console.log("Authenticated with token:", token);
      accessTokenRef.current = token;
    };

    return (
      <TrySubmoduleContainer>
        <TrySubmoduleHeader>Input Resume</TrySubmoduleHeader>
        <TryInputAreaContainer>
          {/* 1: Select Input Method */}
          <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
            <GooglePicker
              clientId={config.googlePicker.clientId}
              developerKey={config.googlePicker.apiKey}
              scope={[
                // "https://www.googleapis.com/auth/drive.readonly",
                "https://www.googleapis.com/auth/drive.metadata.readonly",
                "https://www.googleapis.com/auth/drive.file",
                // "https://www.googleapis.com/auth/documents.readonly",
              ]}
              onAuthenticate={onAuthenticate} // Correctly pass the function
              onChange={handlePickerChange}
              onAuthFailed={(data) => console.log("on auth failed:", data)}
              multiselect={true}
              navHidden={true}
              mimeTypes={["application/vnd.google-apps.document"]}
              query={"resume"}
              viewId={"DOCS"}
            >
              <TryButtonLarge>
                <SVGWrapper $width="64px" $height="64px" $padding="8px">
                  <GoogleDriveSVG />
                </SVGWrapper>
                Select Google Doc
              </TryButtonLarge>
            </GooglePicker>
          </div>
          {/* Display Thumbnail and Next Button after Selection */}
          {tryStore.selectedDoc && tryStore.selectedDoc.thumbnailUrl && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <GoogleDocThumbnail selectedDoc={tryStore.selectedDoc} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TryButton onClick={clearSelectDoc}>Clear</TryButton>
                <TryButton onClick={onNext}>Next</TryButton>
              </div>
            </div>
          )}

          {/* 2A: Paste Resume */}
          {inputMethod === "paste" && (
            <>
              <TryTextArea
                placeholder="Paste your resume here"
                rows={10}
                value={tryStore.resumeInput}
                onChange={handleResumeChange}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TryButton onClick={handleBack}>Back</TryButton>
                <TryButton onClick={onNext}>Next</TryButton>
              </div>
            </>
          )}

          {/* 2B: Upload Resume */}
          {inputMethod === "upload" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <TryButton>
                  <FileUploadIcon />
                  Choose File
                </TryButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TryButton onClick={handleBack}>Back</TryButton>
                <TryButton onClick={onNext}>Next</TryButton>
              </div>
            </>
          )}
        </TryInputAreaContainer>
      </TrySubmoduleContainer>
    );
  }
);
