// src/pages/Make/components/MakeInputJobDescription/MakeInputJobDescription.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { AppContext } from "@contexts/AppContext";
import { makeWebhook } from "../../utils/makeWebhook";

import {
  MakeSubmoduleContainer,
  MakeSubmoduleHeader,
  MakeButton,
  MakeButtonLarge,
  MakeTextArea,
  MakeInput,
} from "../../Make.styled";
import {
  CloudUpload as UploadIcon,
  FontDownload as InputIcon,
  AddToDrive as GoogleDriveIcon,
  FileUploadRounded as FileUploadIcon,
} from "@mui/icons-material";
import { GoogleDocThumbnail } from "../../../../components/GoogleDocThumbnail/GoogleDocThumbnail";
import { WebhookStatusModal } from "../../../../components/WebhookStatusModal/WebhookStatusModal"; // Import the modal
import { WebhookMessage } from "../../utils/webhookConfig"; // Import WebhookMessage

export const MakeInputAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100%;
  min-width: 300px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
`;

export const StyledUploadIcon = styled(UploadIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;

export const StyledInputIcon = styled(InputIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;

interface MakeInputJobDescriptionProps {
  onNext: () => void;
  onBack: () => void;
}

export const MakeInputJobDescription: React.FC<MakeInputJobDescriptionProps> =
  observer(({ onNext, onBack }) => {
    const [inputMethod, setInputMethod] = useState<
      "paste" | "sample" | undefined
    >(undefined);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false); // State to control modal visibility
    const [modalMessage, setModalMessage] = useState<WebhookMessage | null>(
      null
    ); // Changed from string to WebhookMessage
    const { makeStore } = useContext(AppContext); // Destructure makeStore

    // Persist state whenever inputs change
    useEffect(() => {
      makeStore.persistState();
    }, [makeStore.resumeInput, makeStore.jobDescriptionInput]);

    // Handlers to switch input methods
    const handleSelectPaste = () => setInputMethod("paste");
    const handleSelectSample = () => setInputMethod("sample");

    // Handlers for input changes
    const handleJobDescriptionChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      makeStore.setJobDescriptionInput(e.target.value);
    };

    // Function to handle messages from webhook utility
    const handleWebhookMessage = (message: WebhookMessage) => {
      setModalMessage(message); // Set the message object directly
    };

    const handleNext = async () => {
      console.log("Additional logic for onNext");
      console.log(
        "Owner: ",
        makeStore.selectedDoc?.ownerEmail,
        "Name: ",
        makeStore.selectedDoc?.title,
        "ID: ",
        makeStore.selectedDoc?.id,
        "URL: ",
        makeStore.selectedDoc?.url
      );
      try {
        setIsLoading(true);
        setErrorMessage(null); // Clear previous errors
        setModalMessage(null); // Clear previous message
        setModalOpen(true); // Open the modal

        const docOwner = makeStore.selectedDoc?.ownerEmail;
        const docId = makeStore.selectedDoc?.id;
        const jobDescription = makeStore.jobDescriptionInput;

        if (!docOwner) {
          console.error("Document Owner is missing.");
          setErrorMessage("Document Owner is missing.");
          setModalMessage({
            type: "text",
            content: "Document Owner is missing.",
          });
          setModalOpen(false);
          return;
        }

        if (!docId) {
          console.error("Document ID is missing.");
          setErrorMessage("Document ID is missing.");
          setModalMessage({
            type: "text",
            content: "Document ID is missing.",
          });
          setModalOpen(false);
          return;
        }

        if (!jobDescription) {
          console.error("Job Description is missing.");
          setErrorMessage("Job Description is missing.");
          setModalMessage({
            type: "text",
            content: "Job Description is missing.",
          });
          setModalOpen(false);
          return;
        }

        // --- Make Try Webhook Refactored --- //

        // Capture the context returned by makeTryWebhook
        const context = await makeWebhook(
          docOwner,
          docId,
          jobDescription,
          handleWebhookMessage
        );

        // Check and set resumePDFurl in the store
        if (context.resumePDFurl) {
          makeStore.setResumePDFurl(context.resumePDFurl);
          console.log(
            "MobX Store - resumePDFurl updated to:",
            context.resumePDFurl
          );
        } else {
          console.warn(
            "resumePDFurl not found in the webhook response context."
          );
        }

        // Optionally, handle other context data
        // if (context.setData) {
        //   // Do something with setData
        // }

        // Proceed to the next step after webhooks are successfully called
        onNext();
        setModalOpen(false); // Close the modal
      } catch (error) {
        console.error("Error calling makeTryWebhook:", error);
        setErrorMessage("Failed to process your request. Please try again.");
        setModalMessage({
          type: "text",
          content: "Failed to process your request. Please try again.",
        });
        // Optionally, keep the modal open to show error messages
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <MakeSubmoduleContainer>
        <MakeSubmoduleHeader>Input Job Description</MakeSubmoduleHeader>
        {makeStore.selectedDoc && makeStore.selectedDoc.thumbnailUrl && (
          <div style={{ transform: "scale(0.8)", margin: "0", padding: "0" }}>
            <GoogleDocThumbnail selectedDoc={makeStore.selectedDoc} />
          </div>
        )}
        <MakeInputAreaContainer>
          {/* Display Thumbnail and Next Button after Selection */}

          {/* Temporarily disabled input options */}
          {/*
          <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
            <TryButtonLarge onClick={handleSelectPaste}>
              <StyledInputIcon />
              Paste Job Description
            </TryButtonLarge>
            <TryButtonLarge onClick={handleSelectSample}>
              <StyledUploadIcon />
              Select Sample Description
            </TryButtonLarge>
          </div> 
          */}

          {/* Display error message if any */}
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "10px" }}>
              {errorMessage}
            </div>
          )}

          {/* 2A: Paste Job Description */}
          {(inputMethod === "paste" || inputMethod === undefined) && (
            <>
              <MakeTextArea
                placeholder="Paste job description here"
                rows={10}
                value={makeStore.jobDescriptionInput}
                onChange={handleJobDescriptionChange}
                disabled={isLoading}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {/* Changed onClick to call onBack prop */}
                <MakeButton onClick={onBack} disabled={isLoading}>
                  Back
                </MakeButton>
                <MakeButton onClick={handleNext} disabled={isLoading}>
                  {isLoading ? "Processing..." : "Make"}
                </MakeButton>
              </div>
            </>
          )}

          {/* 2B: Select Sample Description */}
          {inputMethod === "sample" && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <MakeButton
                  onClick={() =>
                    makeStore.setJobDescriptionInput("Sample Description 1")
                  }
                  disabled={isLoading}
                >
                  Sample 1
                </MakeButton>
                <MakeButton
                  onClick={() =>
                    makeStore.setJobDescriptionInput("Sample Description 2")
                  }
                  disabled={isLoading}
                >
                  Sample 2
                </MakeButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {/* Changed onClick to call onBack prop */}
                <MakeButton onClick={onBack} disabled={isLoading}>
                  Back
                </MakeButton>
                <MakeButton onClick={handleNext} disabled={isLoading}>
                  {isLoading ? "Processing..." : "Make"}
                </MakeButton>
              </div>
            </>
          )}
        </MakeInputAreaContainer>

        {/* Webhook Status Modal */}
        {modalMessage && (
          <WebhookStatusModal
            isOpen={modalOpen}
            message={modalMessage} // Pass the WebhookMessage object
            onClose={() => setModalOpen(false)}
          />
        )}
      </MakeSubmoduleContainer>
    );
  });
