// src/pages/UserMakes/UserMakes.tsx

import React, { useState } from "react";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app } from "@config/firebaseConfig";
import { useMediaQuery } from "react-responsive";
import * as Primitive from "@components/primitives";
import {
  UserMakesContainer,
  UserMakesHeader,
  UserMakesStyledTable,
  UserMakesTableHeader,
  AllUserMakesButtonContainer,
  AllUserMakesButton,
  UserMakesSVGWrapper,
  UserMakesTableContainer,
} from "./UserMakes.styled";
import { UserMakesTable } from "./UserMakesTable";
import { useUserMakes } from "./hooks/useUserMakes";
import { useUserActions } from "./hooks/useUserActions";
import { GoogleDriveSVG } from "@assets/GoogleDriveSVG";

// Initialize Firebase
const auth = getAuth(app);
const db = getFirestore(app);

export interface UserMake {
  id: string;
  makeDateTimeUNIX: string;
  makeTitle: string;
  makeJobPostURL: string;
  makeFileIDMakeFolder: string;
  makeFileIDMakeResumePDF: string;
  makeStatus: string;
}

export interface UserMakesData {
  userMakes: UserMake[];
  userFolderMakes: string | null;
}

export const UserMakes: React.FC = () => {
  // Utilize the custom hook to manage user makes and related state
  const {
    userMakes,
    userFolderMakes,
    isDeleting,
    isMakingCoverLetter,
    setUserMakes,
    setIsDeleting,
    setIsMakingCoverLetter,
  } = useUserMakes();

  // Utilize the custom hook to handle user actions
  const { handleDelete, handleMakeCoverLetter } = useUserActions({
    setUserMakes,
    setIsDeleting,
    setIsMakingCoverLetter,
  });

  // Open the user's makes folder in Google Drive
  const openUserMakesFolder = () => {
    if (!userFolderMakes) {
      alert("No folder found for user makes.");
      return;
    }
    window.open(
      `https://drive.google.com/drive/folders/${userFolderMakes}`,
      "_blank"
    );
  };

  // Define isWideVW using react-responsive
  const isWideVW = useMediaQuery({ minWidth: 960 });
  const isAbove860px = useMediaQuery({ minWidth: 860 });

  // State to track expanded rows
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  // Function to toggle row expansion
  const toggleRow = (id: string) => {
    // console.log(`Toggling row with id: ${id}`);
    setExpandedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  return (
    <>
      <UserMakesContainer>
        <UserMakesHeader>Dashboard</UserMakesHeader>
        <UserMakesTableContainer>
          <UserMakesStyledTable id="userMakesTable">
            <thead>
              {/* Disabled table header (KEEP THIS) */}
              {/* <tr>
                <UserMakesTableHeader>
                  <span style={{ marginLeft: "-12px" }}>DATE</span>
                </UserMakesTableHeader>
                <UserMakesTableHeader>JOB</UserMakesTableHeader>
                {isAbove860px && (
                  <UserMakesTableHeader>GDRIVE</UserMakesTableHeader>
                )}
                {isAbove860px && (
                  <UserMakesTableHeader>PDF</UserMakesTableHeader>
                )}
                {isWideVW && (
                  <UserMakesTableHeader>STATUS</UserMakesTableHeader>
                )}
                <UserMakesTableHeader>
                  <span style={{ marginRight: "-12px" }}></span>
                </UserMakesTableHeader>
              </tr> */}
            </thead>
            <tbody>
              <UserMakesTable
                makes={userMakes}
                onDelete={handleDelete}
                onMakeCoverLetter={handleMakeCoverLetter}
                isDeleting={isDeleting}
                isMakingCoverLetter={isMakingCoverLetter}
                isWideVW={isWideVW}
                isAbove860px={isAbove860px}
                expandedRows={expandedRows} // Pass expandedRows
                toggleRow={toggleRow} // Pass toggleRow function
              />
            </tbody>
          </UserMakesStyledTable>
        </UserMakesTableContainer>
        <AllUserMakesButtonContainer>
          <AllUserMakesButton onClick={openUserMakesFolder}>
            <UserMakesSVGWrapper>
              <GoogleDriveSVG />
            </UserMakesSVGWrapper>
            <span style={{ marginLeft: "8px" }}>Google Drive</span>
          </AllUserMakesButton>
        </AllUserMakesButtonContainer>
      </UserMakesContainer>
    </>
  );
};
