// src/pages/Make/Make.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { MakeModuleContainer, ProgressTrackerContainer } from "./Make.styled";
import {
  MakeInputResume,
  TryInputJobDescription,
  TryResults,
} from "./components";
import { AppContext } from "@contexts/AppContext";
import { MakeProgressTracker } from "./components/MakeProgressTracker/MakeProgressTracker";

interface MakeModuleProps {
  /* Props */
}

export const MakeModule: React.FC<MakeModuleProps> = observer(() => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { makeStore } = useContext(AppContext);

  // Persist state whenever currentStep changes
  useEffect(() => {
    makeStore.persistState();
  }, [currentStep, makeStore]);

  // Handlers to navigate steps
  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
  const goToStep = (step: number) => setCurrentStep(step);

  return (
    <div>
      <ProgressTrackerContainer id="progress-tracker"></ProgressTrackerContainer>
      <MakeModuleContainer>
        {/* Top Bar */}
        <MakeProgressTracker currentStep={currentStep} goToStep={goToStep} />

        {/* Steps */}
        {currentStep === 0 && <MakeInputResume onNext={handleNext} />}
        {currentStep === 1 && (
          <TryInputJobDescription onNext={handleNext} onBack={handleBack} />
        )}
        {currentStep === 2 && <TryResults onBack={handleBack} />}
      </MakeModuleContainer>
    </div>
  );
});
