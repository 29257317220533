// src/pages/UserMakes/components/ExpandedMakeRow.tsx

import React from "react";
import { ExpandedRow, ExpandedCell } from "../UserMakes.styled";
import { UserMake } from "../UserMakesTable"; // or wherever your UserMake interface is defined

interface ExpandedMakeRowProps {
  colSpan: number;
  makeId: string;
  editedData: Partial<UserMake>;
  onChange: (makeId: string, field: keyof UserMake, value: string) => void;
  onSave: (makeId: string) => void;
}

export const ExpandedMakeRow: React.FC<ExpandedMakeRowProps> = ({
  colSpan,
  makeId,
  editedData,
  onChange,
  onSave,
}) => {
  return (
    <ExpandedRow>
      <ExpandedCell colSpan={colSpan}>
        <div style={{ display: "grid", gap: "1rem" }}>
          {/* Repeat these blocks for each field you want to edit */}
          <div>
            <label style={{ fontWeight: "bold" }}>Make Title:</label>
            <input
              type="text"
              style={{ width: "100%" }}
              value={editedData.makeTitle ?? ""}
              onChange={(e) => onChange(makeId, "makeTitle", e.target.value)}
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Job Post URL:</label>
            <input
              type="text"
              style={{ width: "100%" }}
              value={editedData.makeJobPostURL ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeJobPostURL", e.target.value)
              }
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Folder ID:</label>
            <input
              type="text"
              style={{ width: "100%" }}
              value={editedData.makeFileIDMakeFolder ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeFileIDMakeFolder", e.target.value)
              }
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>PDF File ID:</label>
            <input
              type="text"
              style={{ width: "100%" }}
              value={editedData.makeFileIDMakeResumePDF ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeFileIDMakeResumePDF", e.target.value)
              }
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Make Status:</label>
            <input
              type="text"
              style={{ width: "100%" }}
              value={editedData.makeStatus ?? ""}
              onChange={(e) => onChange(makeId, "makeStatus", e.target.value)}
            />
          </div>
        </div>

        <div style={{ marginTop: "10px", textAlign: "right" }}>
          <button onClick={() => onSave(makeId)}>Save</button>
        </div>
      </ExpandedCell>
    </ExpandedRow>
  );
};
