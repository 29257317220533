// src/pages/Try/components/TryResults/TryResults.tsx

import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { AppContext } from "@contexts/AppContext";
import {
  TrySubmoduleContainer,
  TrySubmoduleHeader,
  TryButton,
} from "../../TryModule.styled";

// Styled-component for the iframe container
export const GDocIframeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80vh; /* Adjusted height to leave space for buttons */
  overflow: hidden;
  background-color: #f5f5f5; /* Optional: Background color while loading */

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    transform-origin: 0 0;
  }

  /* Optional: Style for loading message */
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #888;
    font-size: 1.2em;
  }

  /* Optional: Style for error message */
  .error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: red;
    font-size: 1.2em;
  }
`;

// Define the props for the component
interface TryResultsProps {
  onBack: () => void;
}

export const TryResults: React.FC<TryResultsProps> = observer(({ onBack }) => {
  const { tryStore } = useContext(AppContext); // Access the MobX store from context

  // Handler for the "Continue to Dashboard" button
  const onFinish = () => {
    window.open("https://demo.s-kode.com/", "_blank");
  };

  // Destructure the resumePDFurl from the store for easier access
  const { resumePDFurl } = tryStore;

  return (
    <TrySubmoduleContainer>
      <TrySubmoduleHeader>Results Preview</TrySubmoduleHeader>
      <GDocIframeContainer>
        {resumePDFurl ? (
          <iframe
            src={resumePDFurl}
            title="Resume PDF"
            sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
          />
        ) : (
          <div className="loading">Loading your resume PDF...</div>
        )}
      </GDocIframeContainer>
      {/* Disabled: Test Preview */}
      {/*<div style={{ maxWidth: "600px" }}>
         <img
          src="https://enhancv.com/static/d1b75ac8b27c53faa5fc6540def8013c/1fa92/marissa-mayer-resume.jpg"
          alt="Resume Preview"
          style={{ width: "100%", height: "auto" }}
        ></img> 
      </div>*/}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          marginTop: "20px",
        }}
      >
        {/* <TryButton onClick={onBack}>Back</TryButton> */}
        <TryButton onClick={onFinish}>Continue to Dashboard</TryButton>
      </div>
    </TrySubmoduleContainer>
  );
});
