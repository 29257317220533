// src/pages/UserMakes/components/ExpandedMakeRow.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";
import { UserMake } from "../UserMakesTable";

// New styled components for expanded row
export const ExpandedRow = styled(Primitive.TableRow)`
  max-width: 100%;

  input {
    font-family: ${({ theme }) => theme.fonts.main};
    margin-top: 4px;
    padding: 4px 8px;
  }
`;

export const ExpandedCell = styled(Primitive.TableData)`
  padding: 20px;
  // border-top: 0.5px solid #ddd;
  max-width: 100%;
  overflow-x: scroll;
  background: #eeeeee66 !important; // Light background for expanded content
`;

export const ExpandedTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  resize: vertical;
  padding: 10px;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 14px;
`;

export const SaveButton = styled(Primitive.SquareButton)`
  box-shadow: none;
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  color: white;
`;

interface ExpandedMakeRowProps {
  colSpan: number;
  makeId: string;
  editedData: Partial<UserMake>;
  onChange: (makeId: string, field: keyof UserMake, value: string) => void;
  onSave: (makeId: string) => void;
}

export const ExpandedMakeRow: React.FC<ExpandedMakeRowProps> = ({
  colSpan,
  makeId,
  editedData,
  onChange,
  onSave,
}) => {
  return (
    <ExpandedRow>
      <ExpandedCell colSpan={colSpan}>
        <div style={{ display: "grid", gap: "1rem" }}>
          {/* Repeat these blocks for each field you want to edit */}
          <div>
            <label style={{ fontWeight: "bold" }}>Make Title:</label>
            <br />
            <input
              type="text"
              style={{ width: "90%" }}
              value={editedData.makeTitle ?? ""}
              onChange={(e) => onChange(makeId, "makeTitle", e.target.value)}
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Job Post URL:</label>
            <br />
            <input
              type="text"
              style={{ width: "90%" }}
              value={editedData.makeJobPostURL ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeJobPostURL", e.target.value)
              }
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Folder ID:</label>
            <br />
            <input
              type="text"
              style={{ width: "90%" }}
              value={editedData.makeFileIDMakeFolder ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeFileIDMakeFolder", e.target.value)
              }
              readOnly
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>PDF File ID:</label>
            <br />
            <input
              type="text"
              style={{ width: "90%" }}
              value={editedData.makeFileIDMakeResumePDF ?? ""}
              onChange={(e) =>
                onChange(makeId, "makeFileIDMakeResumePDF", e.target.value)
              }
              readOnly
            />
          </div>

          <div>
            <label style={{ fontWeight: "bold" }}>Make Status:</label>
            <br />
            <input
              type="text"
              style={{ width: "90%" }}
              value={editedData.makeStatus ?? ""}
              onChange={(e) => onChange(makeId, "makeStatus", e.target.value)}
              readOnly
            />
          </div>
        </div>

        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <br />
          <SaveButton onClick={() => onSave(makeId)}>Save</SaveButton>
        </div>
      </ExpandedCell>
    </ExpandedRow>
  );
};
