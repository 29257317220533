// src/pages/UserMakes/hooks/useUserMakes.ts

import { useState, useEffect, useContext, useCallback } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "@config/firebaseConfig";
import { fetchUserMakes } from "../services/userMakesService";
import { AppContext } from "@contexts/AppContext";
import { UserMake } from "../UserMakesTable";

const auth = getAuth(app);

export const useUserMakes = () => {
  const { userStore } = useContext(AppContext);
  const userEmail = userStore.email || userStore.testEmail;

  const [userMakes, setUserMakes] = useState<UserMake[]>([]);
  const [userFolderMakes, setUserFolderMakes] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});
  const [isMakingCoverLetter, setIsMakingCoverLetter] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const fetchMakesData = async (userEmail: string) => {
      try {
        const { userMakes, userFolderMakes } = await fetchUserMakes(userEmail);
        setUserMakes(userMakes);
        setUserFolderMakes(userFolderMakes);
      } catch (error) {
        console.error("Error fetching user makes:", error);
      }
    };

    if (userEmail) {
      fetchMakesData(userEmail);
    } else {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user?.email) {
          fetchMakesData(user.email);
        }
      });

      return () => unsubscribe();
    }
  }, [userEmail]);

  return {
    userMakes,
    setUserMakes,
    userFolderMakes,
    userEmail,
    isDeleting,
    setIsDeleting,
    isMakingCoverLetter,
    setIsMakingCoverLetter,
  };
};
