// src/pages/UserMakes/UserMakes.styled.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";
export {
  AllUserMakesButtonContainer,
  AllUserMakesButton,
  UserMakesSVGWrapper,
} from "./components/AllUserMakesButton.styled";
export {
  DropdownTrigger,
  DropdownContent,
  DropdownItem,
} from "./components/DropdownMenu.styled";

export const UserMakesContainer = styled(Primitive.ModuleContainer)`
  // display: flex;
  width: 100%;
  // align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    padding: 0 0 80px 0;
  }
`;

export const UserMakesHeader = styled(Primitive.ModuleHeader)`
  /* Styles */
`;

export const UserMakesTableContainer = styled.div`
  overflow-x: auto;
  max-width: 100vw;
`;

export const UserMakesStyledTable = styled(Primitive.Table)`
  table-layout: auto;
  width: 100%;

  @media (max-width: 640px) {
    // table-layout: fixed;
  }
`;

export const UserMakesTableHeader = styled(Primitive.TableHeader)`
  /* Styles */
`;

export const UserMakesTableRow = styled(Primitive.TableRow)`
  @media (hover: hover) {
    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const UserMakesTableData = styled(Primitive.TableData)`
  /* Styles */
`;

export const JobTitleCell = styled(Primitive.TableData)`
  /* On mobile, we truncate with ellipses */
  white-space: normal;
  overflow-x: scroll;
  // max-width: 250px;
  text-overflow: ellipsis;

  @media (max-width: 640px) {
    white-space: nowrap;
    overflow-x: scroll;
    text-overflow: ellipsis;
    max-width: 260px;
  }
`;

export const UserMakesDateHeaderRow = styled.tr`
  color: #777;
  font-size: 0.75rem;
  // font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover {
    filter: brightness(0.9);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const UserMakesDateHeaderRowData = styled(Primitive.TableData)`
  background-color: ${({ theme }) => theme.colors.grayLight};
`;

// New styled components for expanded row
export const ExpandedRow = styled(Primitive.TableRow)`
  background-color: #555; // Light background for expanded content
`;

export const ExpandedCell = styled(Primitive.TableData)`
  padding: 20px;
  border-top: 1px solid #555;
`;

export const ExpandedTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  resize: vertical;
  padding: 10px;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 14px;
`;
