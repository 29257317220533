// src/pages/BaseResumes/BaseResumesTable.tsx

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";
import {
  BaseResumesTableRow,
  BaseResumesTableData,
  // Removed BaseResumesDefaultCheck as it's no longer needed
  // BaseResumesDefaultCheck,
  // BaseResumesStyledIcon,
  // SetDefaultButton,
  DeleteButton,
  BaseResumesSVGWrapper,
} from "./BaseResumes.styled";
import { ItemMenuBaseResumes } from "./ItemMenuBaseResumes";
import { GoogleDocSVG } from "@assets/GoogleDocSVG";
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  AccessTimeFilledRounded as AccessTimeFilledRoundedIcon,
} from "@mui/icons-material";

export const DefaultResumeCheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 24px;
  background: none;
  overflow-x: scroll;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }

  svg {
    &:hover {
      cursor: pointer;
      fill: green;
    }
  }
`;

interface UserBaseResume {
  id: string;
  baseResumeName: string;
  baseResumeFileIDGDoc: string;
  makeDateTimeUNIX: string;
  makeStatus: string;
  isDefault: boolean;
}

interface BaseResumesTableProps {
  makes: UserBaseResume[];
  onDelete: (id: string) => void;
  onSetDefault: (id: string) => void;
  isDeleting: { [key: string]: boolean };
  isSettingDefault: { [key: string]: boolean };
  expandedRows: string[]; // New prop
  toggleRow: (id: string) => void; // New prop
}

export const BaseResumesTable: React.FC<BaseResumesTableProps> = ({
  makes,
  onDelete,
  onSetDefault,
  isDeleting,
  isSettingDefault,
  expandedRows,
  toggleRow,
}) => {
  if (makes.length === 0) {
    return (
      <BaseResumesTableRow>
        <BaseResumesTableData colSpan={7}>
          No base resumes found.
        </BaseResumesTableData>
      </BaseResumesTableRow>
    );
  }

  return (
    <>
      {makes.map((resume) => {
        const isExpanded = expandedRows.includes(resume.id);
        const resumeDate = new Date(parseInt(resume.makeDateTimeUNIX, 10));
        const formattedDate = resumeDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        return (
          <React.Fragment key={resume.id}>
            <BaseResumesTableRow
              onClick={() => toggleRow(resume.id)}
              style={{ cursor: "pointer" }} // Indicate clickable row
            >
              {/* Date */}
              <BaseResumesTableData>{formattedDate}</BaseResumesTableData>

              {/* Name */}
              <BaseResumesTableData>
                {resume.baseResumeName ? (
                  resume.baseResumeName
                ) : (
                  <span style={{ color: "grey", fontStyle: "italic" }}>
                    Untitled
                  </span>
                )}
              </BaseResumesTableData>

              {/* Google Doc */}
              <BaseResumesTableData>
                <a
                  href={`https://docs.google.com/document/d/${resume.baseResumeFileIDGDoc}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()} // Prevent row click
                >
                  <Primitive.TableDataContentCentered>
                    <Primitive.TableSVGButtonWrapper>
                      <BaseResumesSVGWrapper>
                        <GoogleDocSVG />
                      </BaseResumesSVGWrapper>
                    </Primitive.TableSVGButtonWrapper>
                  </Primitive.TableDataContentCentered>
                </a>
              </BaseResumesTableData>

              {/* Set Default */}
              <BaseResumesTableData>
                <DefaultResumeCheckContainer
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    onSetDefault(resume.id);
                  }}
                  aria-label={
                    resume.isDefault
                      ? "Default Resume"
                      : "Set as Default Resume"
                  }
                  title={
                    resume.isDefault
                      ? "This is the default resume"
                      : "Click to set as default resume"
                  }
                >
                  {resume.isDefault ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </DefaultResumeCheckContainer>
              </BaseResumesTableData>

              {/* Item Menu */}
              <BaseResumesTableData>
                <ItemMenuBaseResumes
                  trigger={
                    <Primitive.TableDataContentCentered>
                      <Primitive.TableSVGButtonWrapper>
                        <BaseResumesSVGWrapper>
                          <MoreVertIcon />
                        </BaseResumesSVGWrapper>
                      </Primitive.TableSVGButtonWrapper>
                    </Primitive.TableDataContentCentered>
                  }
                  sideOffset={8}
                  align="end"
                  onSetDefault={() => onSetDefault(resume.id)}
                  onDelete={() => onDelete(resume.id)}
                />
              </BaseResumesTableData>
            </BaseResumesTableRow>

            {/* Expanded Row */}
            {isExpanded && (
              <BaseResumesTableRow>
                <BaseResumesTableData colSpan={5}>
                  <textarea
                    style={{
                      width: "100%",
                      height: "150px",
                      resize: "vertical",
                      padding: "10px",
                      boxSizing: "border-box",
                    }}
                    value={JSON.stringify(resume, null, 2)}
                    onChange={(e) => {
                      // Handle JSON editing if needed
                      // For now, it's read-only
                    }}
                    readOnly // Make it editable by removing this line if needed
                  />
                </BaseResumesTableData>
              </BaseResumesTableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
