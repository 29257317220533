// src/pages/Make/utils/makeWebhook.ts

import { WebhookConfig, webhookConfigs, WebhookMessage } from "./webhookConfig";

/**
 * Sleeps for the specified number of milliseconds.
 * @param ms Number of milliseconds to sleep.
 * @returns A Promise that resolves after the specified time.
 */
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Default delay values
const DEFAULT_PRE_SEND_DELAY = 0; // No delay by default
const DEFAULT_RESPONSE_DISPLAY_DURATION = 1000; // 1 second by default

/**
 * Helper function to log objects in a human-readable format.
 * @param message The message to log before the object.
 * @param obj The object to log.
 */
const logHumanReadable = (message: string, obj: any) => {
  console.log(`${message}:\n${JSON.stringify(obj, null, 2)}`);
};

/**
 * Processes a series of webhooks based on predefined configurations.
 * @param docOwner Document owner to send to the webhooks.
 * @param docId Document ID to send to the webhooks.
 * @param jobDescription Job description to send to the webhooks.
 * @param onMessage Callback to send messages to the modal.
 * @returns A Promise that resolves to the context containing stored data.
 */
export const makeWebhook = async (
  docOwner: string,
  docId: string,
  jobDescription: string,
  onMessage: (message: WebhookMessage) => void
): Promise<{ [key: string]: any }> => {
  // Initialize a context object to store data between webhooks
  const context: { [key: string]: any } = {};

  for (const webhook of webhookConfigs) {
    try {
      // Determine delays, using defaults if not specified
      const preSendDelay = webhook.preSendDelay ?? DEFAULT_PRE_SEND_DELAY;
      const responseDisplayDuration =
        webhook.responseDisplayDuration ?? DEFAULT_RESPONSE_DISPLAY_DURATION;

      // Apply pre-send delay if specified
      if (preSendDelay > 0) {
        console.log(
          `Waiting for ${preSendDelay}ms before sending start message for Webhook ${webhook.id}`
        );
        await sleep(preSendDelay);
      }

      // Send the custom start message
      onMessage(webhook.startMessage);
      console.log(`Sent start message for Webhook ${webhook.id}`);

      // Prepare the POST body
      let postBody: { [key: string]: any } = {
        docOwner,
        docId,
        jobDescription,
      };

      // If there are any dependencies, merge them into the postBody
      if (webhook.dependencies) {
        for (const dep of webhook.dependencies) {
          if (context[dep.key] !== undefined) {
            postBody[dep.alias || dep.key] = context[dep.key];
          }
        }
      }

      // Make the POST request to the webhook
      const response = await fetch(webhook.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      });

      // Handle non-OK responses
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Status ${response.status}: ${errorText || "No error message provided."}`
        );
      }

      // Parse the JSON response
      const responseData = await response.json();

      // Handle responseData being an array or object
      const actualResponseData = Array.isArray(responseData)
        ? responseData[0]
        : responseData;

      // If the webhook has keys to store data in context, do so
      if (webhook.storeResponseAs) {
        if (Array.isArray(webhook.storeResponseAs)) {
          webhook.storeResponseAs.forEach((key) => {
            if (actualResponseData[key] !== undefined) {
              context[key] = actualResponseData[key];
            } else {
              console.warn(
                `Warning: Key '${key}' not found in responseData for Webhook ${webhook.id}.`
              );
            }
          });
        } else {
          if (actualResponseData[webhook.storeResponseAs] !== undefined) {
            context[webhook.storeResponseAs] =
              actualResponseData[webhook.storeResponseAs];
          } else {
            console.warn(
              `Warning: Key '${webhook.storeResponseAs}' not found in responseData for Webhook ${webhook.id}.`
            );
          }
        }
      }

      // Log the full response data in a human-readable format
      logHumanReadable(
        `Parsed Response Data for Webhook ${webhook.id}`,
        actualResponseData
      );

      // Send the custom success message
      onMessage(webhook.successMessage(responseData));
      console.log(`Sent success message for Webhook ${webhook.id}`);

      // Apply response display duration delay if specified
      if (responseDisplayDuration > 0) {
        console.log(
          `Displaying response message for Webhook ${webhook.id} for ${responseDisplayDuration}ms`
        );
        await sleep(responseDisplayDuration);
      }
    } catch (error) {
      // Ensure the error is an instance of Error
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred.";

      // Send the custom error message
      onMessage(webhook.errorMessage(new Error(errorMessage)));
      console.error(`Error in Webhook ${webhook.id}:`, error);

      // Determine delays, using defaults if not specified
      const responseDisplayDuration =
        webhook.responseDisplayDuration ?? DEFAULT_RESPONSE_DISPLAY_DURATION;

      // Apply response display duration delay if specified
      if (responseDisplayDuration > 0) {
        console.log(
          `Displaying error message for Webhook ${webhook.id} for ${responseDisplayDuration}ms`
        );
        await sleep(responseDisplayDuration);
      }

      // Decide whether to continue or halt on error
      // For now, we'll halt the process
      throw error;
    }

    // Add a delay after each webhook response (general delay between webhooks)
    // await sleep(1000); // 1 second delay
  }

  return context; // Return the context containing stored data
};
