// src/pages/Make/utils/webhookConfig.ts

export type WebhookMessageType = "text" | "iframe";

export interface WebhookMessage {
  type: WebhookMessageType;
  content: string;
}

export interface WebhookDependency {
  key: string; // The key in the context to retrieve
  alias?: string; // Optional alias to use in the request body
}

export interface WebhookConfig {
  id: number; // Unique identifier for the webhook
  url: string; // Webhook URL
  startMessage: WebhookMessage; // Message to display when starting the webhook
  successMessage: (responseData: any) => WebhookMessage; // Function to format success messages
  errorMessage: (error: Error) => WebhookMessage; // Function to format error messages
  preSendDelay?: number; // Optional delay before sending the start message (in ms)
  responseDisplayDuration?: number; // Optional delay after sending the response message (in ms)
  dependencies?: WebhookDependency[]; // Optional dependencies on previous webhooks
  storeResponseAs?: string | string[]; // Optional key(s) to store specific response data
}

export const webhookConfigs: WebhookConfig[] = [
  {
    // Check User Account
    id: 1,
    url: "https://n8n.s-kode.com/webhook/make_Try1",
    startMessage: { type: "text", content: "Checking user account..." },
    successMessage: (responseData) => ({
      type: "text",
      content: `User: ${responseData.response || "Operation completed successfully."}`,
    }),
    errorMessage: (error) => ({
      type: "text",
      content: `Check User Account Error: ${error.message}`,
    }),
    preSendDelay: 0,
    responseDisplayDuration: 500,
  },
  {
    // Setup Base Resume
    id: 2,
    url: "https://n8n.s-kode.com/webhook/make_Try2",
    startMessage: { type: "text", content: "Setting Up Base Resume..." },
    successMessage: (responseData) => {
      // Check if responseData is an array and has at least one element
      const response =
        Array.isArray(responseData) && responseData.length > 0
          ? responseData[0].response
          : null;

      return {
        type: "iframe",
        content:
          response ||
          "https://docs.google.com/document/d/YOUR_DEFAULT_DOC_ID/edit",
      };
    },
    errorMessage: (error) => ({
      type: "text",
      content: `Setup Base Resume Error: ${error.message}`,
    }),
    preSendDelay: 0,
    responseDisplayDuration: 2000,
  },
  {
    // Analyze Job Description
    id: 3,
    url: "https://n8n.s-kode.com/webhook/make_Try3",
    startMessage: { type: "text", content: "Analyzing Job Description..." },
    successMessage: (responseData) => ({
      type: "text",
      content: `Analyze Job Description Successful!`,
    }),
    errorMessage: (error) => ({
      type: "text",
      content: `Analyze Job Description Error: ${error.message}`,
    }),
    preSendDelay: 0,
    responseDisplayDuration: 2000,
    storeResponseAs: "setData", // Store the 'setData' from this webhook's response
  },
  {
    // Make Resume
    id: 4,
    url: "https://n8n.s-kode.com/webhook/make_Try4",
    startMessage: { type: "text", content: "Making Customized Resume..." },
    successMessage: (responseData) => ({
      type: "text",
      content: `Make Resume Success: ${responseData.response || "Operation completed successfully."}`,
    }),
    errorMessage: (error) => ({
      type: "text",
      content: `Make Resume Error: ${error.message}`,
    }),
    preSendDelay: 0,
    responseDisplayDuration: 0,
    dependencies: [
      {
        key: "setData", // Key from the context to include in the request
      },
    ],
    storeResponseAs: ["setData", "resumePDFurl"], // Store both 'setData' and 'resumePDFurl'
  },
];
