// src/pages/SetupResume/SetupResume.styled.tsx

// Define Styled Components

import React from "react";
import styled from "styled-components";
import * as Primitive from "@components/primitives";

// Container
export const SetupResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Header
export const SetupResumeHeader = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

// Form
export const SetupResumeForm = styled(Primitive.Form)`
  display: flex;
  align-content: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

// Label
export const SetupResumeLabel = styled(Primitive.Label)`
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
  font-size: 0.75rem;
`;

// Input fields
export const SetupResumeInput = styled(Primitive.Input)`
  min-width: 300px;
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const SetupResumeButton = styled(Primitive.SquareButton)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SubmitButton = styled(SetupResumeButton)`
  color: white;
  background-color: ${({ theme }) => theme.colors.buttonPrimary};
  max-width: max-content;
  padding: 10px 60px;
`;

// Status message
export const SetupResumeStatusMessage = styled(
  Primitive.LoadingMessageContainer
)`
  margin-top: 30px;
  height: 20px;
`;

export const Button = styled.button`
  font-family: ${({ theme }) => theme.fonts.main};
`;

export const SetupResumeButtonLarge = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 120px;
  margin: 10px 0;
  margin-top: 32px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #aaa;
  background-color: #eee;
  font-size: 0.9rem;
  font-weight: 550;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;

  @media (hover: hover) {
    &:hover {
      background-color: #ddd;
      transform: scale(1.03); /* Optional: add a slight zoom effect */
    }
  }
`;
