// src/pages/Try/TryModule.tsx

import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { TryModuleContainer } from "./TryModule.styled";
import {
  TryInputResume,
  TryInputJobDescription,
  TryResults,
  TryProgressTracker,
} from "./components";
import { AppContext } from "@contexts/AppContext";

interface TryModuleProps {
  /* Props */
}

export const TryModule: React.FC<TryModuleProps> = observer(() => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { tryStore } = useContext(AppContext);

  // Persist state whenever currentStep changes
  useEffect(() => {
    tryStore.persistState();
  }, [currentStep, tryStore]);

  // Handlers to navigate steps
  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handleBack = () => setCurrentStep((prev) => Math.max(prev - 1, 0));
  const goToStep = (step: number) => setCurrentStep(step);

  return (
    <>
      <TryModuleContainer>
        {/* Progress Tracker */}
        <TryProgressTracker currentStep={currentStep} goToStep={goToStep} />
        {/* Steps */}
        {currentStep === 0 && <TryInputResume onNext={handleNext} />}
        {currentStep === 1 && (
          <TryInputJobDescription onNext={handleNext} onBack={handleBack} />
        )}
        {currentStep === 2 && <TryResults onBack={handleBack} />}
      </TryModuleContainer>
    </>
  );
});
