// src/pages/Make/components/MakeInputResume/MakeInputResume.styled.tsx

import styled from "styled-components";
import {
  CloudUpload as UploadIcon,
  FontDownload as InputIcon,
} from "@mui/icons-material";

export const MakeInputAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100%;
  min-width: 300px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
`;

export const StyledUploadIcon = styled(UploadIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;

export const StyledInputIcon = styled(InputIcon)`
  color: #aaa;
  font-size: 3rem !important;
`;
