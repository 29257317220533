// src/domains/Try/components/GoogleDocThumbnail/GoogleDocThumbnail.tsx

import React, { useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

export const GDocThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;

  img {
    width: 150px;
    height: auto;
    cursor: pointer;
    border-radius: 8px;
    transition: filter 0.1s ease;
    border: 1px solid #aaaaaa00;

    @media (hover: hover) {
      &:hover {
        filter: brightness(0.9);
        border: 1px solid #aaa;
      }
    }
  }

  /* Overlay for File Name */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 5px 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-size: 0.5rem;
    z-index: 10;
  }

  @media (hover: hover) {
    &:hover .overlay {
      opacity: 1;
    }
  }
`;

interface GoogleDocThumbnailProps {
  selectedDoc: {
    id: string;
    title: string;
    url: string;
    mimeType: string;
    thumbnailUrl?: string;
    ownerEmail?: string;
    createdDate?: string;
    modifiedDate?: string;
  } | null;
}

export const GoogleDocThumbnail: React.FC<GoogleDocThumbnailProps> = observer(
  ({ selectedDoc }) => {
    // const store = useContext(AppContext);

    /* if (store.selectedDoc) {
      console.log("Thumbnail URL:", store.selectedDoc.thumbnailUrl);
    } */

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {selectedDoc ? (
          selectedDoc.thumbnailUrl ? (
            <GDocThumbnailWrapper>
              <a
                href={selectedDoc.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    width: "100%",
                    padding: "5px 0",
                    fontSize: "0.75rem",
                    top: "0",
                    zIndex: "50",
                    background: "rgba(0, 0, 0, 0.6)",
                    borderTopRightRadius: "8px",
                    borderTopLeftRadius: "8px",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Base Resume
                </div>
                <div className="overlay">{selectedDoc.title}</div>
                <img
                  src={selectedDoc.thumbnailUrl}
                  alt={`${selectedDoc.title} Thumbnail`}
                />
              </a>
            </GDocThumbnailWrapper>
          ) : (
            // Placeholder if no thumbnail
            <GDocThumbnailWrapper>
              <div
                style={{
                  width: "150px",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "0.85rem",
                    marginBottom: "8px",
                    color: "#555",
                  }}
                >
                  Base Resume: {selectedDoc.title}
                </span>
                <button
                  onClick={() =>
                    selectedDoc && window.open(selectedDoc.url, "_blank")
                  }
                  style={{
                    background: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  Open Document
                </button>
              </div>
            </GDocThumbnailWrapper>
          )
        ) : (
          <p>No document selected.</p>
        )}
      </div>
    );
  }
);
