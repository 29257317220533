// src/components/primitives/Tables.styled.tsx

import styled from "styled-components";

export const Table = styled.table`
  // width: max-content;
  width: 100%;
  margin: 0 auto;

  // max-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  margin: 0 auto;
  box-sizing: border-box;
  table-layout: auto;

  /* Responsive Styles */
  @media (max-width: 640px) {
    font-size: 12px;

    th,
    td {
      font-size: 0.9rem;
      padding: 8px 0px;
    }

    /* .make-cl-btn,
    .delete-btn {
      font-size: 10px;
      padding: 4px 6px;
    } */

    /* .icon {
      height: 24px;
    } */

    /* .user-makes-btn-all {
      font-size: 12px;
      padding: 8px 12px;
    } */

    /* .date-header {
      font-size: 13px;
      padding: 8px;
    } */
  }
`;

export const TableHeader = styled.th`
  // background-color: ${({ theme }) => theme.colors.grayLight};
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.7rem;
  font-weight: bold;
  padding: 6px 0px;
  margin-bottom: 4px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.medium};
    padding-left: 10px;
  }

  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.medium};
    padding-right: 20px;
  }
`;

export const TableRow = styled.tr`
  font-size: 0.9rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) td {
    border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  }
`;

export const TableData = styled.td`
  padding: 6px 8px;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableDataContentCentered = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const TableDataContentLeft = styled.div`
  display: flex;
  text-align: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TableSVGButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
  background-color: #eee;
  width: 32px;
  height: 32px;
  border-radius: 15%;

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const TableSVGWrapper = styled.div<{ size?: number }>`
  width: ${({ size }) => size || 24}px;
  height: ${({ size }) => size || 24}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  /* &:hover {
    filter: brightness(0.8);
  } */
`;
