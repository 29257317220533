// src/pages/UserMakes/components/DropdownMenu.styled.ts

import styled from "styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

// Styled Components for Dropdown Menu
export const DropdownTrigger = styled(DropdownMenu.Trigger)`
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const DropdownContent = styled(DropdownMenu.Content)`
  min-width: 160px;
  background-color: white;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled(DropdownMenu.Item)`
  padding: 8px 12px;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
`;
