// src/components/SideBar/SideBar.styled.ts

import styled, { css } from "styled-components";

interface SidebarContainerProps {
  $sidebarWidth: number;
  $isMobile: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  position: fixed;
  left: 0;
  top: 56px;
  width: ${({ $sidebarWidth }) => `${$sidebarWidth}px`};
  height: calc(100vh - 56px);
  // background-color: #2c3e50;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  z-index: 50;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  margin-top: 0;
  transition:
    width 0.3s ease,
    transform 0.3s ease; /* Smooth width and transform transition */
  overflow: hidden; /* Hide overflowing text when collapsed */
  font-size: 15px;

  ${({ $isMobile, $sidebarWidth }) =>
    $isMobile &&
    css`
      /* On mobile, make the sidebar overlay the content */
      position: fixed;
      width: ${$sidebarWidth}px;
      height: 100vh;
      top: 56px;
      left: 0;
      transform: translateX(${$sidebarWidth > 0 ? "0" : "-100%"});
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    `}
`;

export const MenuItem = styled.div<{ $active: boolean; $sidebarWidth: number }>`
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 9px;
  color: ${({ $active }) => ($active ? "#3498db" : "#ecf0f1")};
  cursor: pointer;
  transition:
    background 0.3s,
    color 0.3s;

  @media (hover: hover) {
    &:hover {
      background-color: #34495e;
    }
  }

  span {
    opacity: ${({ $sidebarWidth }) => ($sidebarWidth > 60 ? 1 : 0)};
    margin-left: 10px; /* Space between icon and text */
    white-space: nowrap;
    transition:
      opacity 0.3s ease,
      margin-left 0.3s ease;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Adjust this as needed */
  min-width: 40px; /* Ensures consistency */
`;

export const SeparatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0;
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
  height: 1px;
  width: calc(100% - 20px);
  border-bottom: 0.25px solid rgba(255, 255, 255, 0.25);
`;
