// src/layouts/Dashboard/useSideBarLogic.ts

import { useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useSidebarLogic = () => {
  const [isMenuToggled, setIsMenuToggled] = useState<boolean>(false);
  const [isSidebarHovered, setIsSidebarHovered] = useState<boolean>(false);

  // Collapsed vs expanded widths
  const collapsedWidth = 60;
  const expandedWidth = 250;

  // Use react-responsive to detect if the device is mobile
  const isMobile = useMediaQuery({ maxWidth: 640 });

  // Decide sidebar width based on the toggled/hover states
  const sidebarWidth =
    isMenuToggled || isSidebarHovered ? expandedWidth : collapsedWidth;

  const handleTopBarMenuClick = () => {
    setIsMenuToggled((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsSidebarHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsSidebarHovered(false);
    }
  };

  const handleCloseSidebar = () => {
    if (isMobile) {
      setIsMenuToggled(false);
    }
  };

  return {
    isMenuToggled,
    setIsMenuToggled,
    isSidebarHovered,
    setIsSidebarHovered,
    collapsedWidth,
    expandedWidth,
    isMobile,
    sidebarWidth,
    handleTopBarMenuClick,
    handleMouseEnter,
    handleMouseLeave,
    handleCloseSidebar,
  };
};
