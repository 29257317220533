// src/config/firebaseConfig.ts

import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
  Firestore,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";

// Firebase configuration object with types
const firebaseConfig = {
  apiKey: "AIzaSyCeM0DBCblcR8o4AbNQmTuII9_UOkn-azI",
  authDomain: (() => {
    const hostname = window.location.hostname;
    if (hostname.includes("demo.s-kode.com")) return "demo.s-kode.com";
    if (hostname.includes("app.s-kode.com")) return "app.s-kode.com";
    if (hostname.includes("demo.runojobs.com")) return "demo.runojobs.com";
    if (hostname.includes("app.runojobs.com")) return "app.runojobs.com";
    return "app.runojobs.com"; // fallback domain if needed
  })(),
  projectId: "ramauthtest",
  storageBucket: "ramauthtest.appspot.com",
  messagingSenderId: "480885640389",
  appId: "1:480885640389:web:ecc2c8e57a128423beb4ac",
  measurementId: "G-KYQ3WXB7GF",
};

// Initialize Firebase app
const app: FirebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore and Auth instances with types
const db: Firestore = getFirestore(app);
const auth: Auth = getAuth(app);

// Enable emulators for local development
if (window.location.hostname === "localhost") {
  // Connect to Firestore Emulator on port 8080
  connectFirestoreEmulator(db, "localhost", 8082);

  // Connect to Auth Emulator on port 9099
  connectAuthEmulator(auth, "http://localhost:9099");
}

// Export Firebase app, Firestore, and Auth instances
export { app, db, auth };
