// src/pages/BaseResumes/services/baseResumesService.ts

import {
  getFirestore,
  collection,
  getDocs,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { app } from "@config/firebaseConfig";
import { FIRESTORE_PATHS } from "config";
import { UserBaseResume } from "../types";

const db = getFirestore(app);

export const fetchUserBaseResumes = async (
  email: string
): Promise<UserBaseResume[]> => {
  console.log("Fetching user base resumes for email:", email);

  const userDocRef = doc(db, FIRESTORE_PATHS.userDoc(email));
  const userBaseResumesRef = collection(
    db,
    FIRESTORE_PATHS.userBaseResumes(email)
  );

  const q = query(userBaseResumesRef);

  const userDocSnapshot = await getDoc(userDocRef);
  const defaultBaseResumeId = userDocSnapshot.data()?.defaultBaseResume;
  console.log("Default base resume ID:", defaultBaseResumeId);

  const querySnapshot = await getDocs(q);
  const userBaseResumes: UserBaseResume[] = querySnapshot.docs.map(
    (docSnap) => {
      console.log("Processing resume:", docSnap.id, docSnap.data());
      const data = docSnap.data();
      return {
        id: docSnap.id,
        baseResumeName: data.baseResumeName,
        baseResumeFileIDGDoc: data.baseResumeFileIDGDoc,
        makeDateTimeUNIX: data.makeDateTimeUNIX,
        makeStatus: data.makeStatus,
        isDefault: docSnap.id === defaultBaseResumeId,
      };
    }
  );

  console.log("Fetched user base resumes:", userBaseResumes);
  return userBaseResumes;
};
