// src/components/UserDisplay/hooks/useFetchUserData.ts

import { useEffect, useState } from "react";
import { fetchUserData } from "../services/fetchUserData";
import { UserData } from "../types/userData";

export const useFetchUserData = (email: string | null): UserData | null => {
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    if (email) {
      fetchUserData(email)
        .then((data) => setUserData(data))
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [email]);

  return userData;
};
