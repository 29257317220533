// src/theme/colors.ts

const colors = {
  secondary: "#677",
  white: "#fff",
  black: "#000",
  grayLight: "#eee",
  grayMed: "#ccc",
  text: "#333",
  buttonPrimary: "#15b",
  buttonText: "#fff",
  bgPrimary: "#f0f0f0",
  bgSecondary: "#345",
  // Add more colors as needed
};

export default colors;
